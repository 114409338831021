<template>
  <div>
    <div class="py-6">

      <div class="w-full p-8 text-center border-red-400 border mx-auto max-w-sm lg:max-w-4xl mb-4 rounded text-red-800"
           v-if="terminatedSession">
        <p>Your session has been inactive for too long. Please log in again to continue</p>
      </div>

      <div class="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
        <div class="w-full p-8">
          <div class="mt-4">
            <label class="block text-gray-700 text-sm font-bold mb-2">Email Address</label>
            <input
                class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                type="email" v-model="inputs.email">
          </div>
          <div class="mt-4">
            <div class="flex justify-between">
              <label class="block text-gray-700 text-sm font-bold mb-2">Password</label>
            </div>
            <input
                class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                type="password" v-model="inputs.password">
          </div>
          <div class="mt-8">
            <button class="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600"
                    @click="processLogin">Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'Login',
  data: () => {
    return {
      inputs: {
        email: '',
        password: ''
      }
    }
  },
  computed: {
    terminatedSession: function () {
      return this.$route.query.terminatedSession === "1"
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    processLogin: function () {
      this.login(this.inputs)
          .finally((resp) => {
            this.$router.push({name: 'homepage'})
          })
    }
  }
}
</script>

<style scoped>

</style>